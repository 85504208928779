import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Home.vue')
    },
    {
        path: '/code',
        name: 'Code',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Code.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/myprofile",
        name: 'Myprofile',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Myprofile.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/users",
        name: 'Users',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Users.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/online",
        name: 'Online',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Online.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/ofac",
        name: 'OFAC',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/OFAC.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/unsc",
        name: 'UNSC',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/UNSC.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/eus",
        name: 'EUS',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/EUS.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/nst",
        name: 'NST',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/NST.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/allprograms",
        name: 'Allprograms',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Allprograms.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/roles",
        name: 'Roles',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Roles.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/apikey",
        name: 'APIKey',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/APIKey.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/logs",
        name: 'Logs',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Logs.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/modules",
        name: 'Modules',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Modules.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/pages",
        name: 'Pages',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Pages.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/reports",
        name: 'Reports',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Reports.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/business",
        name: 'Business',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Business.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/persons",
        name: 'Persons',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Persons.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/kpi",
        name: 'KPI',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/KPI.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/list",
        name: 'List',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/List.vue'),
        meta: {
            authRequired: true
        }
    },



];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});






export default router;
